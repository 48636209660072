import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Estilos para el fondo oscuro
const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

// Estilos para el banner de cookies
const CookieWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1000;
  max-width: 90%;
  text-align: center;
`;

const Button = styled.button`
  background: #000;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  
  &:hover {
    background: #333;
  }
`;

function CookieCompliance() {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (!cookiesAccepted) {
      setShowBanner(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <>
      <Backdrop />
      <CookieWrapper>
        <p>Este sitio utiliza cookies para mejorar su experiencia. ¿Acepta el uso de cookies?</p>
        <Button onClick={acceptCookies}>Aceptar</Button>
      </CookieWrapper>
    </>
  );
}

export default CookieCompliance;
