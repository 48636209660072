import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { NavLink } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Container, Row, Col, Card, Button, ListGroup, Image, Form } from 'react-bootstrap';
import styled from 'styled-components';
import sec1 from '../placeholder/sec1.png';
import sec2 from '../placeholder/sec2.png';
import secojo from '../placeholder/secojo.png';
import pajarocam from '../placeholder/pajarocam.png';
import secu1 from '../placeholder/secu1.mp4';

const StyledCard = styled(Card)`
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;
const StyledContainer = styled(Container)`
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
  margin-top: 2rem;
`;

const BannerSection = styled.section`
  position: relative;
  text-align: center;
  margin-bottom: 5rem;
  height: 60vh;
  background-image: url(./placeholder/pajarocam.png); /* Reemplaza con la ruta de tu imagen */
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Oscurecer la imagen de fondo */
    z-index: 1;
  }

  > div {
    position: relative;
    z-index: 2;
  }

  h1 {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

const NavLinkStyled = styled(NavLink)`
  color: #fff;
  background-color: #000;
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  text-decoration: none;
  border-radius: 0.25rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #333;
  }
`;

const HomePage = () => {
  return (
    <StyledContainer>
      <BannerSection>
        <div>
          <h1 className="display-4 font-weight-bold mb-4">BARNA SEGUR</h1>
          <p className="lead mb-4">La mejor protección.</p>
          <NavLinkStyled to="#productos" className="btn btn-dark btn-lg">
            Explorar
          </NavLinkStyled>
        </div>
      </BannerSection>

      <section id="servicios" className="py-5">
        <h2 className="text-center mb-4">PORQUE NOSOTROS</h2>
        <Row>
          {['Calidad', 'Confiabilidad', 'Innovación'].map((benefit) => (
            <Col key={benefit} md={4} className="mb-4">
              <Card className="h-100">
                <Card.Body>
                  <Card.Title>{benefit}</Card.Title>
                  <Card.Text>Somos una empresa lider en el sector de la seguridad, des de hace ya muchos años con una reputación más que consolidada.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </section>

      <section className="py-5 bg-dark text-white text-center">
        <p className="lead">Pide tu presupuesto ahora y consigue hasta un 20% de descuento!</p>
      </section>

      <section className="py-5">
        <h2 className="text-center mb-4">Nuestra promesa de protección</h2>
        <Row className="align-items-center">
          <Col md={6} className="mb-4 mb-md-0">
            <Image src="./placeholder/sec2.png" rounded fluid />
          </Col>
          <Col md={6}>
            <p className="lead mb-4">Aseguramos el máximo nivel de protección.</p>
            <ListGroup>
              <ListGroup.Item>24/7 Soporte</ListGroup.Item>
              <ListGroup.Item>Opciones de pago seguro</ListGroup.Item>
              <ListGroup.Item>Totalmente confiable y con garantía</ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </section>

      <section className="py-5 bg-light">
        <h2 className="text-center mb-4">Mira nuestra historia</h2>
        <div className="ratio ratio-16x9">
          <iframe
            src={secu1}
            title="Mira nuestra historia"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="w-100 h-100"
          ></iframe>
        </div>
      </section>

      <section className="py-5">
        <h2 className="text-center mb-4">Medidas Anti-Ocupacion</h2>
        <Row>
          <Col md={6} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>Nuestro Commetido</Card.Title>
                <Card.Text>Nos esforzamos por mantener un entorno seguro e integrador para todos nuestros clientes y empleados.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <Card.Body>
                <Card.Title>Nuestras Acciones</Card.Title>
                <ListGroup>
                  <ListGroup.Item>Auditorías de seguridad periódicas</ListGroup.Item>
                  <ListGroup.Item>Programas de formación para empleados</ListGroup.Item>
                  <ListGroup.Item>Colaboración con las autoridades locales</ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>


      <section id="productos" className="py-5">
        <h3 className="text-center mb-4">Protección para personas dentro y fuera de casa</h3>
        <p className="text-center mb-5">Cuidamos de tu hogar y de los tuyos, las 24 horas.</p>
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={30}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {[
            {
              title: 'Ayuda inmediata frente a emergencias en casa',
              description: [
                'Si tienes una emergencia o sufres un accidente, puedes solicitar ayuda en cualquier momento pulsando los botones SOS de la alarma.',
                'Y si hay humo en el ambiente, nuestro detector de incendios activa su sirena de alta potencia y nos envía una alerta inmediata.'
              ],
              image: {sec1}
            },
            {
              title: 'SOS y acompañamiento, estés donde estés',
              description: [
                'Con Guardián Verisure, podrás pedir ayuda desde tu móvil ante cualquier emergencia fuera de casa con solo un clic.',
                'Podrás solicitar que te acompañemos en un trayecto de riesgo; por ejemplo, cuando tus hijos vuelven solos a casa.'
              ],
              image: {sec2}
            },
            {
              title: 'Avisamos por ti a emergencias y abrimos la puerta a la ayuda',
              description: [
                'Si lo necesitas, avisamos a emergencias con tu ubicación exacta y te acompañamos hasta que llegue la ayuda.',
                'Además, si estás en casa, abrimos la puerta en remoto a los bomberos o la ambulancia para que te atiendan sin perder un segundo.'
              ],
              image: {secojo}
            },
          ].map((slide, index) => (
            <SwiperSlide key={index}>
              <Card className="h-100">
                <Card.Img variant="top" src={slide.image} />
                <Card.Body>
                  <Card.Title>{slide.title}</Card.Title>
                  <Card.Text as="ul">
                    {slide.description.map((desc, i) => (
                      <li key={i}>{desc}</li>
                    ))}
                  </Card.Text>
                </Card.Body>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>

      <section className="py-5 bg-light">
        <h3 className="text-center mb-4">¿Qué incluye tu alarma?</h3>
        <Row>
          {[
            { icon: {secojo}, title: "Garantía y mantenimiento" },
            { icon: {sec1}, title: "Equipo de alarma con sistema anti-sabotaje y anti-inhibición" },
            { icon: {sec2}, title: "Central Receptora de Alarmas disponible 24h/365 días" },
            { icon: {pajarocam}, title: "App móvil para controlar tu alarma estés donde estés" },
            { icon: {sec1}, title: "Vigilante sin límite de intervenciones" },
          ].map((item, index) => (
            <Col key={index} md={6} lg={4} className="mb-4">
              <Card className="h-100 d-flex flex-row align-items-center">
                <Card.Img src={item.icon} className="w-25 h-25 p-3" />
                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </section>

      <section id="contacto" className="py-5">
        <h2 className="text-center mb-4">Contactanos</h2>
        <Row className="justify-content-center">
          <Col md={8} lg={6}>
            <StyledCard className="p-4">
              <Form>
                <Form.Group controlId="formName" className="mb-3">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control type="text" placeholder="Enter your name" />
                </Form.Group>
                <Form.Group controlId="formEmail" className="mb-3">
                  <Form.Label>Correo</Form.Label>
                  <Form.Control type="email" placeholder="info@barnasegur.es" />
                </Form.Group>
                <Form.Group controlId="formMessage" className="mb-3">
                  <Form.Label>Mensage</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder="Enter your message" />
                </Form.Group>
                <Button variant="dark" type="submit" className="w-100">Enviar</Button>
              </Form>
            </StyledCard>
          </Col>
        </Row>
      </section>
    </StyledContainer>
  );
};

export default HomePage;