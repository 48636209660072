import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  width: 100%;
  background-color: #1a1a1a;
  color: #ffffff;
  text-align: center;
  padding: 1.5rem 0;
  border-top: 1px solid #333;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const FooterText = styled.p`
  margin: 0;
  font-size: 1rem;
`;


function Footer() {
  return (
    <FooterWrapper>
        <FooterText>
          <p>&copy; 2024 Barna Segur. Todos los derechos reservados.</p>
      </FooterText>
    </FooterWrapper>
  );
}

export default Footer;